import { FunctionComponent } from "preact";
import { Button } from "./button";

const BTN_VALUES = [1, 2, 3, 4, 5];

export const QuickAnswerBtns: FunctionComponent<{
  buttonLabel?: string;
  onClick?: (val: number) => void;
}> = ({ buttonLabel, onClick }) => {
  return (
    <div className="">
      <div className="flex">
        <Button className="flex-1" onClick={() => onClick?.(0)}>
          {buttonLabel || "Не обнаружено"}
        </Button>
      </div>
      <div className="mt-2 flex">
        {BTN_VALUES.map((v) => (
          <Button
            className="mr-2 flex-1 last:mr-0"
            onClick={() => onClick?.(v)}
          >
            {v}
          </Button>
        ))}
      </div>
    </div>
  );
};
