import { FunctionalComponent } from "preact";
import { Field, InputWrapper } from ".";
import { FormNavigation, useForm } from "./form";

export const DogsLocationStep: FunctionalComponent<{}> = () => {
  const { state, setState } = useForm();

  const streetValue = state["street"];
  const houseValue = state["house"];

  const handleInputChange =
    (fieldName: "street" | "house") => (val: string | undefined) => {
      setState((state) => ({ ...state, [fieldName]: val }));
    };

  const canNavNext = !!streetValue && !!houseValue;

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex-1">
        <InputWrapper title="Улица">
          <Field
            value={streetValue}
            placeholder="Укажите улицу"
            onInput={(e) => handleInputChange("street")(e.currentTarget.value)}
          />
          <p className="text-right text-text_color">*обязательное поле</p>
        </InputWrapper>
        <InputWrapper title="Дом">
          <Field
            value={houseValue}
            placeholder="Укажите дом"
            onInput={(e) => handleInputChange("house")(e.currentTarget.value)}
          />
          <p className="text-right text-text_color">*обязательное поле</p>
        </InputWrapper>
      </div>
      <FormNavigation disabled={!canNavNext} />
    </div>
  );
};
