import { FunctionComponent } from "preact";

interface CircleProgressProps {
  progress: number; // Progress should be a number between 0 and 100
  size?: number; // Diameter of the circle
  strokeWidth?: number; // Width of the circle's stroke
  color?: string; // Color of the progress stroke
  bgColor?: string; // Background color of the circle
}

export const CircleProgress: FunctionComponent<CircleProgressProps> = ({
  progress,
  size = 100,
  strokeWidth = 10,
}) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <svg width={size} height={size}>
      <circle
        fill="transparent"
        strokeWidth={strokeWidth}
        className="stroke-white/20"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        stroke="white"
        fill="transparent"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        className="origin-center -rotate-90 transition-[stroke-dasharray]"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
    </svg>
  );
};
