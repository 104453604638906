export function useSearchParams(): Record<string, string>;
export function useSearchParams(param: string): string | null;
export function useSearchParams(params: string[]): Record<string, string>;

export function useSearchParams(
  param?: string | string[],
): Record<string, string> | string | null {
  const params = new URLSearchParams(window.location.search);

  if (!param) {
    return [...params].reduce(
      (acc, [key, value]) => ((acc[key] = value), acc),
      {} as Record<string, string>,
    );
  }

  if (Array.isArray(param)) {
    return param.reduce(
      (acc, key) => {
        const val = params.get(key);
        if (val) {
          acc[key] = val;
        }
        return acc;
      },
      {} as Record<string, string>,
    );
  }

  return params.get(param);
}
