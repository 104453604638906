import classNames from "classnames";
import { FunctionComponent } from "preact";

import { CssProps } from "../types";

type ButtonProps = CssProps &
  Partial<{
    onClick: () => void;
    disabled: boolean;
  }>;

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  disabled = false,
  className,
  onClick,
}) => {
  return (
    <button
      className={classNames(
        className,
        "rounded-lg bg-button_color p-2 text-lg font-bold text-button_text_color disabled:opacity-60",
      )}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
