import { FunctionComponent } from "preact";

export const InputWrapper: FunctionComponent<{
  children: preact.ComponentChildren;
  title: string;
}> = ({ children, title }) => {
  return (
    <div>
      <h4 class="mb-2 text-2xl font-bold text-text_color">{title}</h4>
      {children}
    </div>
  );
};
