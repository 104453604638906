import "./index.css";

// import render from "preact-render-to-string";

import { WebAppProvider } from "@zakarliuka/react-telegram-web-tools";
import { render } from "preact";
import { App } from "./app";

// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://2893e2687113579a82f779087c840dd1@sentry.dtc.tatar/6",
//   environment: "production",
//   integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 0.01, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// if (document?.body?.tabIndex !== undefined) {
//   document.body.tabIndex = 1;
// }
render(
  <WebAppProvider>
    <App />
  </WebAppProvider>,
  document.getElementById("app") as HTMLElement,
);
