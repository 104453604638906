import { createContext, FunctionalComponent, isValidElement } from "preact";
import { StateUpdater, useContext, useMemo, useState } from "preact/hooks";
import { cn } from "../lib/utils";
import { Button } from "./button";

interface FormState {
  [key: string]: any;
}

interface FormContextProps {
  state: FormState;
  setState: StateUpdater<FormState>;
  currentStep: number;
  setCurrentStep: (step: number) => void;
  totalSteps: number;
  hasNext: boolean;
  goNext: () => void;
  hasPrev: boolean;
  goPrev: () => void;
  submit: () => void;
}

const FormContext = createContext<FormContextProps | undefined>(undefined);

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a Form");
  }
  return context;
};

const Form: FunctionalComponent<{
  children: preact.ComponentChildren;
  defaultStep?: number;
  initialData?: FormState;
  submit: (state: FormState) => void;
}> = ({ children, defaultStep = 0, initialData = {}, submit }) => {
  const [state, setState] = useState<FormState>(initialData);
  const [currentStep, setCurrentStep] = useState(defaultStep);

  const steps = useMemo(() => {
    const isFormContent = (child: any): child is preact.VNode<any> =>
      isValidElement(child) && child.type === FormContent;
    const isFormStep = (child: any): child is preact.VNode<any> =>
      isValidElement(child) && child.type === FormStep;

    if (Array.isArray(children)) {
      const container = children.find(isFormContent);

      if (container && Array.isArray(container.props.children)) {
        return container.props.children.reduce((prev: number, child: any) => {
          return (isFormStep(child) ? 1 : 0) + prev;
        }, 0);
      }
    } else if (isFormContent(children)) {
      const containerChildren = children.props.children;

      if (Array.isArray(containerChildren)) {
        return containerChildren.reduce((prev, child) => {
          return (isFormStep(child) ? 1 : 0) + prev;
        }, 0);
      } else if (isFormStep(containerChildren)) {
        return 1;
      }
    }

    return 0;
  }, [children]);

  const hasNext = currentStep < steps - 1;
  const hasPrev = currentStep > 0;

  const goTo = (step: number) => {
    setCurrentStep(Math.max(Math.min(step, steps), 0));
  };

  const goNext = () => {
    goTo(currentStep + 1);
  };

  const goPrev = () => {
    goTo(currentStep - 1);
  };

  return (
    <FormContext.Provider
      value={{
        state,
        setState,
        currentStep,
        setCurrentStep,
        totalSteps: steps,
        hasNext,
        goNext,
        hasPrev,
        goPrev,
        submit: () => submit(state),
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const FormContent: FunctionalComponent<{
  children: preact.ComponentChildren;
  className?: string;
}> = ({ children, className }) => {
  return <div className={cn("w-full", className)}>{children}</div>;
};

const FormStep: FunctionalComponent<{
  step: number;
  children: preact.ComponentChildren;
}> = ({ step, children }) => {
  const { currentStep } = useForm();

  return currentStep === step ? <>{children}</> : null;
};

const FormNavigation: FunctionalComponent<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { hasNext, hasPrev, goNext, goPrev } = useForm();

  return (
    <div className="w-full">
      <div className="flex flex-row space-x-4">
        {hasPrev && (
          <Button className="w-full" onClick={goPrev}>
            Назад
          </Button>
        )}
        {hasNext && (
          <Button className="w-full" onClick={goNext} disabled={disabled}>
            Далее
          </Button>
        )}
      </div>
    </div>
  );
};

export { Form, FormContent, FormNavigation, FormStep };
