import { api } from './api';

type UploadResponse = {
  file_link: string;
  id: string;
};

export const uploadFile = async (
  ticketId: string,
  file: File,
  onProgress: (progress: number) => void,
  signal: AbortSignal,
) => {
  const formData = new FormData();
  formData.append('picture_gallery', file);

  try {
    const response = await api.post<UploadResponse[]>(
      '/tickets/upload-images',
      formData,
      {
        headers: {
          Authorization: `Ticket ${ticketId}`,
        },
        signal,
        onUploadProgress: (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / (event.total ?? 100)) * 100;
            onProgress(progress);
          }
        },
      },
    );

    return response.data.map((r) => ({
      fileLink: r.file_link,
      id: r.id,
    }));
  } catch (error) {
    throw error;
  }
};
