import heic2any from "heic2any";
import { createContext, FunctionalComponent, FunctionComponent } from "preact";
import {
  StateUpdater,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "preact/hooks";
import { JSXInternal } from "preact/src/jsx";
import { isPresent } from "../helpers";
import { useUploadImage } from "../hooks/useUploadImage";
import { cn } from "../lib/utils";
import { CircleProgress } from "./circle-progress";

const MAX_FILE_SIZE = 20 * 1024 * 1024;
const MAX_FILES = 9;

type Attachment = {
  file: File;
  previewUrl: string;
  error?: boolean;
  progress?: number;
  abortController?: AbortController;
  uploaded?: { fileLink: string; id: string };
};

interface AttachmentsContextProps {
  attachments: Attachment[];
  setAttachments: StateUpdater<Attachment[]>;
  availableFileCount: number;
}

const AttachmentsContext = createContext<AttachmentsContextProps | undefined>(
  undefined,
);

export const useAttachments = () => {
  const context = useContext(AttachmentsContext);
  if (!context) {
    throw new Error("useAttachments must be used within a Attachments");
  }
  return context;
};

interface AttachmentsProviderProps {
  children: preact.ComponentChildren;
  attachments?: Attachment[];
  setAttachments?: (attachments: Attachment[]) => void;
}

export const Attachments: FunctionComponent<AttachmentsProviderProps> = ({
  children,
  attachments = [],
  setAttachments,
}) => {
  const [internalAttachments, setInternalAttachments] =
    useState<Attachment[]>(attachments);

  useEffect(() => {
    setInternalAttachments(attachments);
  }, [attachments]);

  const updateAttachments: StateUpdater<Attachment[]> = useCallback(
    (value) => {
      if (typeof value === "function") {
        setInternalAttachments((prevAttachments) => {
          const newAttachments = (
            value as (prevState: Attachment[]) => Attachment[]
          )(prevAttachments);
          setAttachments?.(newAttachments);
          return newAttachments;
        });
      } else {
        setInternalAttachments(value);
        setAttachments?.(value);
      }
    },
    [setAttachments],
  );

  const availableFileCount = MAX_FILES - internalAttachments.length;

  return (
    <AttachmentsContext.Provider
      value={{
        attachments: internalAttachments,
        setAttachments: updateAttachments,
        availableFileCount,
      }}
    >
      {children}
    </AttachmentsContext.Provider>
  );
};

export const AttachedPreview: FunctionalComponent<{
  attachment: Attachment;
}> = ({ attachment }) => {
  const uploadFile = useUploadImage();

  const { attachments, setAttachments } = useAttachments();
  const isLoading =
    !isPresent(attachment.uploaded) && !isPresent(attachment.error);

  const handleDelete = () => {
    if (attachment.abortController) {
      attachment.abortController.abort(); // Cancel the upload
    }
    setAttachments(attachments.filter((att) => att.file !== attachment.file));
    URL.revokeObjectURL(attachment.previewUrl);
  };

  const handleRetry = async () => {
    const abortController = new AbortController();
    setAttachments((attachments) =>
      attachments.map((att) =>
        att.file === attachment.file
          ? { ...att, error: undefined, abortController }
          : att,
      ),
    );

    try {
      const onProgress = (progress: number) => {
        setAttachments((attachments) =>
          attachments.map((att) =>
            att.file === attachment.file ? { ...att, progress } : att,
          ),
        );
      };

      const [uploaded] = await uploadFile(
        attachment.file,
        onProgress,
        abortController.signal,
      );

      setAttachments((attachments) =>
        attachments.map((att) =>
          att.file === attachment.file ? { ...att, uploaded } : att,
        ),
      );
      URL.revokeObjectURL(attachment.previewUrl);
    } catch (error) {
      setAttachments((attachments) =>
        attachments.map((att) =>
          att.file === attachment.file ? { ...att, error: true } : att,
        ),
      );
    }
  };

  return (
    <div
      className={cn(
        "relative flex aspect-square w-full items-center justify-center rounded-md bg-black",
        attachment.error && "bg-red-500 text-white",
      )}
    >
      <button
        className="absolute -right-1 -top-1 z-10 flex items-center justify-center rounded-full bg-button_color p-1 text-button_text_color"
        onClick={handleDelete}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-x"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      </button>

      {isLoading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-md bg-black/60">
          <CircleProgress
            strokeWidth={2}
            size={34}
            progress={attachment.progress ?? 0}
          />
        </div>
      )}
      {attachment.error && (
        <button
          className="flex h-full w-full flex-1 items-center justify-center"
          onClick={handleRetry}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-rotate-ccw"
          >
            <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
            <path d="M3 3v5h5" />
          </svg>
        </button>
      )}
      {!attachment.error && (attachment.uploaded || attachment.previewUrl) && (
        <img
          src={attachment.uploaded?.fileLink || attachment.previewUrl}
          className="h-full w-full rounded-md object-cover object-center"
          onLoadStart={(evt) => {
            if (evt.target instanceof HTMLImageElement) {
              evt.target.style.display = "block";
            }
          }}
          // onError={(evt) => {
          //   if (evt.target instanceof HTMLImageElement) {
          //     evt.target.style.display = "none";
          //   }
          // }}
        />
      )}
    </div>
  );
};

export const AttachmentsList = () => {
  const { attachments } = useAttachments();

  return (
    <div className="grid grid-cols-3 gap-3">
      {attachments.map((attachment, index) => (
        <AttachedPreview
          attachment={attachment}
          key={`${attachment.file.name}-${index}`}
        />
      ))}
    </div>
  );
};

export const UploadFile: FunctionalComponent<{
  children?: preact.ComponentChildren;
}> = ({ children }) => {
  const { availableFileCount, setAttachments } = useAttachments();
  const uploadFile = useUploadImage();

  // const handleFileChange = (
  //   evt: JSXInternal.TargetedEvent<HTMLInputElement, Event>,
  // ) => {
  //   const files = Array.from(evt.currentTarget.files || []).filter(
  //     (f) => f.size <= MAX_FILE_SIZE,
  //   );

  //   files.slice(0, availableFileCount).forEach(async (file) => {
  //     const previewUrl = URL.createObjectURL(file);
  //     const abortController = new AbortController();
  //     const newAttachment: Attachment = { file, previewUrl, abortController };
  //     setAttachments((attachments) => [...attachments, newAttachment]);

  //     try {
  //       const onProgress = (progress: number) => {
  //         setAttachments((attachments) =>
  //           attachments.map((att) =>
  //             att.file === file ? { ...att, progress } : att,
  //           ),
  //         );
  //       };

  //       const [uploaded] = await uploadFile(
  //         file,
  //         onProgress,
  //         abortController.signal,
  //       );

  //       setAttachments((attachments) =>
  //         attachments.map((att) =>
  //           att.file === file ? { ...att, uploaded } : att,
  //         ),
  //       );
  //       URL.revokeObjectURL(previewUrl);
  //     } catch (error) {
  //       setAttachments((attachments) =>
  //         attachments.map((att) =>
  //           att.file === file ? { ...att, error: true } : att,
  //         ),
  //       );
  //     }
  //   });

  //   evt.currentTarget.value = "";
  // };

  const handleFileChange = async (
    evt: JSXInternal.TargetedEvent<HTMLInputElement, Event>,
  ) => {
    const files = Array.from(evt.currentTarget.files || []).filter(
      (f) => f.size <= MAX_FILE_SIZE,
    );

    for (const file of files.slice(0, availableFileCount)) {
      const previewUrl = URL.createObjectURL(file);
      const abortController = new AbortController();
      const newAttachment: Attachment = { file, previewUrl, abortController };
      setAttachments((attachments) => [...attachments, newAttachment]);

      try {
        // Convert HEIC to JPEG if necessary
        let fileToUpload = file;
        if (file.type === "image/heic") {
          const convertedBlob = await heic2any({
            blob: file,
            toType: "image/jpeg",
          });
          if (convertedBlob) {
            fileToUpload = new File(
              [convertedBlob as BlobPart],
              file.name.replace(/\.[^.]+$/, ".jpg"),
              {
                type: "image/jpeg",
              },
            );
          }
        }

        const onProgress = (progress: number) => {
          setAttachments((attachments) =>
            attachments.map((att) =>
              att.file === file ? { ...att, progress } : att,
            ),
          );
        };

        const [uploaded] = await uploadFile(
          fileToUpload,
          onProgress,
          abortController.signal,
        );

        setAttachments((attachments) =>
          attachments.map((att) =>
            att.file === file ? { ...att, uploaded } : att,
          ),
        );
        URL.revokeObjectURL(previewUrl);
      } catch (error) {
        setAttachments((attachments) =>
          attachments.map((att) =>
            att.file === file ? { ...att, error: true } : att,
          ),
        );
      }
    }

    if (evt.target) {
      (evt.target as HTMLInputElement).value = "";
    }
  };

  if (availableFileCount === 0) {
    return null;
  }

  return (
    <div className="relative">
      <label>
        <input
          type="file"
          accept=".heic,image/*"
          className="pointer-events-none absolute left-0 top-0 h-px w-px cursor-pointer appearance-none opacity-0"
          tabIndex={-1}
          multiple
          onInput={handleFileChange}
        />
        {children ? (
          children
        ) : (
          <div className="field flex w-full cursor-pointer flex-row items-center justify-center space-x-1 bg-button_color text-button_text_color">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-camera"
            >
              <path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z" />
              <circle cx="12" cy="13" r="3" />
            </svg>

            <span>Прикрепить фотографии</span>
          </div>
        )}
      </label>
    </div>
  );
};
