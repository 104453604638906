import { isPresent } from "../helpers";
import { FormNavigation, useForm } from "./form";
import { NumberInput } from "./number-input";

export function DogsCountStep() {
  const { state, setState } = useForm();

  const chippedDog = state["chippedDog"];
  const dogsCaught = state["dogsCaught"];
  const dontCaughtDog = state["dontCaughtDog"];

  const handleInputChange =
    (fieldName: "dogsCaught" | "chippedDog" | "dontCaughtDog") =>
    (val: number | undefined) => {
      setState((state) => ({ ...state, [fieldName]: val }));
    };

  const hasNextStep =
    isPresent(chippedDog) || isPresent(dogsCaught) || isPresent(dontCaughtDog);

  return (
    <div className="flex flex-1 flex-col space-y-5">
      <div className="flex-1">
        <NumberInput
          title="Поймано собак"
          buttonLabel="Не поймано"
          value={dogsCaught}
          onChange={handleInputChange("dogsCaught")}
        />
        <NumberInput
          title="Упущено собак"
          buttonLabel="Не упущено"
          value={dontCaughtDog}
          onChange={handleInputChange("dontCaughtDog")}
        />
        <NumberInput
          title="Чипированные собаки"
          buttonLabel="Не обнаружено"
          value={chippedDog}
          onChange={handleInputChange("chippedDog")}
        />
      </div>
      <FormNavigation disabled={!hasNextStep} />
    </div>
  );
}
