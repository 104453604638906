import { useEffect } from "preact/hooks";

function useOutside<T extends HTMLElement = HTMLElement>(
  ref: preact.RefObject<T>,
  handler?: (e: MouseEvent) => void,
) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !ref.current ||
        ref.current.contains((event?.target as Node) || null)
      ) {
        return;
      }
      handler?.(event);
    };
    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
}

export default useOutside;
