import { FunctionComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { JSXInternal } from "preact/src/jsx";
import { Field } from "./field";
import { InputWrapper } from "./input-wrapper";
import { QuickAnswerBtns } from "./quick-answer-btns";

const DEFAULT_MAX_VALUE = 100;

export const NumberInput: FunctionComponent<{
  title: string;
  value?: number;
  buttonLabel?: string;
  max?: number;
  onChange?: (val?: number) => void;
}> = ({ title, value, buttonLabel, max, onChange }) => {
  const [internalValue, setInternalValue] = useState<number | undefined>(value);

  const maxVal = max || DEFAULT_MAX_VALUE;

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (val?: number) => {
    setInternalValue(val);
    onChange?.(val);
  };

  const handleInput = (evt: JSXInternal.TargetedEvent<HTMLInputElement>) => {
    const inputValue = evt.currentTarget.value.replace(/[^0-9]/, "");
    const newValue =
      inputValue !== "" ? Math.min(parseInt(inputValue), maxVal) : undefined;

    evt.currentTarget.value = newValue?.toString() || "";
    handleChange(newValue);
  };

  const numberInputKeyDown = (
    event: JSXInternal.TargetedKeyboardEvent<HTMLInputElement>,
  ) => {
    const eventCode = event.code.toLowerCase();
    if (
      !(
        event.code !== null &&
        (eventCode.includes("digit") ||
          eventCode.includes("arrow") ||
          eventCode.includes("home") ||
          eventCode.includes("end") ||
          eventCode.includes("backspace") ||
          (eventCode.includes("numpad") && eventCode.length === 7))
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <InputWrapper title={title}>
      <Field
        inputMode="numeric"
        value={internalValue}
        onInput={handleInput}
        onKeyDown={numberInputKeyDown}
        placeholder="Укажите количество собак"
      />
      <div className="mt-4">
        <QuickAnswerBtns onClick={handleChange} buttonLabel={buttonLabel} />
      </div>
    </InputWrapper>
  );
};
