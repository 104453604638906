import { createContext, FunctionalComponent } from "preact";
import { useContext } from "preact/hooks";

interface AppContextProps {
  ticketId: string;
  photosRequired: boolean;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: FunctionalComponent<{
  ticketId: string;
  photosRequired?: boolean;
}> = ({ children, ticketId, photosRequired = false }) => {
  return (
    <AppContext.Provider value={{ ticketId, photosRequired }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppSettings = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
};
