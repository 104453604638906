import { FunctionComponent } from "preact";
import { useRef } from "preact/hooks";
import { JSXInternal } from "preact/src/jsx";
import useOutside from "../hooks/useOutside";

export const Field: FunctionComponent<
  Omit<JSXInternal.HTMLAttributes<HTMLInputElement>, "className">
> = (props) => {
  const ref = useRef<HTMLInputElement>(null);

  useOutside(ref, () => {
    ref?.current?.blur();
  });

  return <input {...props} className="field text-2xl" ref={ref} />;
};
