import {
  useClose,
  useExpand,
  useSendData,
} from "@zakarliuka/react-telegram-web-tools";
import { DogsCountStep } from "./components/dogs-count-step";

import { useEffect } from "preact/hooks";
import { DogsDescriptionStep } from "./components/dogs-description-step";
import { DogsLocationStep } from "./components/dogs-location-step";
import { Form, FormContent, FormStep } from "./components/form";
import { AppProvider } from "./components/ticket-provider";
import { useSearchParams } from "./hooks";

export function App() {
  const {
    ticket_id: ticketId,
    is_planned,
    photos_required,
  } = useSearchParams(["ticket_id", "is_planned", "photos_required"]);

  const isPlanned = is_planned === "true";
  const isPhotosRequired = photos_required === "true";

  const sendData = useSendData();
  const close = useClose();

  const [, expand] = useExpand();

  useEffect(() => {
    expand();
  }, [expand]);

  // const onSendData = useCallback(() => {

  // const showSubmit =
  //   (isPlanned ? [street, house].every(Boolean) : true) &&
  //   [chippedDog, dogsCaught, dontCaughtDog].some(isPresent);

  // useEffect(() => {
  //   if (showSubmit) {
  //     mainButton.setText("Отправить!").show();
  //   } else {
  //     mainButton.hide();
  //   }
  // }, [showSubmit]);

  // useEffect(() => {
  //   mainButton.onClick(onSendData);
  //   return () => {
  //     mainButton.offClick(onSendData);
  //   };
  // }, [onSendData]);

  // const handleTextArea = (
  //   evt: JSXInternal.TargetedEvent<HTMLTextAreaElement>,
  // ) => {
  //   setDescription(evt.currentTarget.value);
  // };

  return (
    <AppProvider ticketId={ticketId} photosRequired={true}>
      <div className="mx-auto flex min-h-[var(--tg-viewport-height)] max-w-sm flex-1 flex-col justify-center space-y-4 p-4">
        <Form
          submit={(state) => {
            const plannedFields = {
              is_planned: isPlanned,
              dog_habitat_street: state.street,
              dog_habitat_house: state.house,
            };

            const attachments = Array.isArray(state.attachments)
              ? state.attachments
                  .filter((att) => Boolean(att.uploaded))
                  .map((att) => ({
                    file_link: att.uploaded.fileLink,
                    id: att.uploaded.id,
                  }))
              : null;

            const toSend = JSON.stringify({
              dogs_caught: state.dogsCaught || null,
              chipped_dog: state.chippedDog || null,
              dont_caught_dog: state.dontCaughtDog || null,
              ticket_id: ticketId,
              description: state.description || null,
              picture_gallery: attachments
                ? attachments.length === 0
                  ? null
                  : attachments
                : null,
              ...(isPlanned ? plannedFields : null),
            });
            // alert(toSend);

            // close();

            sendData(toSend);
          }}
        >
          <FormContent className="flex h-full flex-1 flex-col">
            {isPlanned && (
              <FormStep step={0} key={"step-0"}>
                <DogsLocationStep />
              </FormStep>
            )}
            <FormStep step={1 - (isPlanned ? 0 : 1)} key={"step-1"}>
              <DogsCountStep />
            </FormStep>
            <FormStep step={2 - (isPlanned ? 0 : 1)} key={"step-2"}>
              <DogsDescriptionStep />
            </FormStep>
          </FormContent>
        </Form>
      </div>
    </AppProvider>
  );
}
