const tg = window.Telegram.WebApp;

export function useTelegram() {
  const close = () => {
    tg.close();
  };

  return {
    close,
    tg,
    mainButton: tg.MainButton,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
  };
}
